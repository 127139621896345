<template>
  <div class="container-fluid">
    <div class="page-header mb-1">
      <strong>ตั้งค่า เปิด/ปิด เกม</strong>
    </div>

    <table class="table table-bordered table-striped table-sm table-info">
      <thead>
        <tr>
          <th rowspan="2" width="60">ลำดับ</th>
          <th rowspan="2" width="150">ชื่อใช้งาน</th>
          <th rowspan="2" width="150">ระดับ</th>
          <th rowspan="2" width="150">เปิด/ปิด</th>
          <th :colspan="catItems.length">เปิด/ปิด หมวดหมู่</th>
        </tr>
        <tr>
          <th v-for="cat in catItems" :key="cat._id">{{ cat.name }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items">
          <td class="text-center">{{ index+1 }}</td>
          <td class="text-center">{{ item.user.username }}</td>
          <td class="text-center">{{ item.level.levelName }}</td>
          <td class="text-center">
            <b-form-checkbox v-model="item.isAvailable" name="check-button" switch @change="toggleChange(item)" :class="[item.isAvailable ? 'text-success' : 'text-danger']">
              {{ item.isAvailable ? 'เปิด' : 'ปิด' }}
            </b-form-checkbox>
          </td>
          <td v-for="cat in catItems" :key="cat._id" class="text-center">
            <b-form-checkbox v-if="item.isAvailable" v-model="item.catSettings[cat._id]" name="check-button" switch @change="toggleChange(item)" :class="[item.catSettings[cat._id] ? 'text-success' : 'text-danger']">
              {{ item.catSettings[cat._id] ? 'เปิด' : 'ปิด' }}
            </b-form-checkbox>
            <i v-else class="fas fa-times text-secondary"></i>
          </td>
        </tr>
        <tr v-if="!items.length">
          <td class="text-center" :colspan="4 + catItems.length">ไม่มีข้อมูล</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import GameService from '@/services/gameService'
import Swal from 'sweetalert2'

export default {
  name: 'UserSettingAvailableGame',
  data() {
    return {
      categories: [],
      accounts: null
    }
  },
  computed: {
    catItems() {
      return this.categories.filter((cat)=>{
        return cat.status === 'Active'
      })
      .map((cat)=>{
        return {
          _id: cat._id,
          name: cat.name
        }
      })
    },
    items() {
      return this.accounts ?? []
    }
  },
  methods: {
    loadCats() {
      GameService.getCategories().then((response)=>{
        if(response.success) {
          this.categories = response.data
        }
      })
    },
    loadAccounts() {
      GameService.gameAccounts().then((response)=>{
        if(response.success) {
          this.accounts = response.data
        }
      })
    },
    toggleChange(account) {
      const updateData = {
        accountIds: [account.accountId],
        isAvailable: account.isAvailable,
        catSettings: account.catSettings
      }
      this.updateStatus(updateData)
    },
    updateStatus(data) {
      GameService.updateGameAvailable(data)
      .then((response)=>{
        if(!response.success) {
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ไม่สำเร็จ!',
          text: e.message,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.loadAccounts()
      })
    }
  },
  mounted() {
    this.loadCats()
    this.loadAccounts()
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        text-align: center;
        font-weight: normal;
      }
    }
  }

  tbody {
    background-color: #FFFFFF;
  }
}
</style>
